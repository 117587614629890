<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal(false)"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Histórico de preços') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>
        <b-overlay
          :show="processingAjax"
          rounded="sm"
        >
          <vue-perfect-scrollbar
            class="b-sidebar-scrollarea-form"
            :settings="configSettingsScroll"
          >
            <b-container
              class="pt-2 pb-0 pl-2 pr-2"
              fluid
            >
              <app-timeline class="mt-1 mr-1">
                <app-timeline-item
                  v-for="(row) in dataPrices"
                  :key="row.id"
                  no-body
                  icon="HashIcon"
                  variant="primary"
                >
                  <div class="justify-content align-items-start">
                    <!-- 1st Col -->
                    <b-row>
                      <b-col
                        md="12"
                        class="mb-1"
                      >
                        <div class="mb-1 mb-sm-0">
                          <span class="text-muted mb-50 d-block">{{ $t('Data de registo') }}</span>
                          <span>{{ row.data }}</span>
                        </div>
                      </b-col>
                      <b-col
                        md="12"
                        class="mb-1"
                      >
                        <div class="mb-1 mb-sm-0">
                          <span class="text-muted mb-50 d-block">{{ $t('Registado por') }}</span>
                          <span>{{ row.nome }}</span>
                        </div>
                      </b-col>
                      <b-col
                        md="12"
                      >
                        <div class="mb-1 mb-sm-0">
                          <span class="text-muted mb-50 d-block">{{ $t('Preço') }}</span>
                          <span>{{ formatPrice(row.preco) }}</span>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </app-timeline-item>
              </app-timeline>

            </b-container>

          </vue-perfect-scrollbar>
          <div
            class="d-flex p-1 justify-content-center"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click.stop.prevent="hide"
            >
              {{ $t('Fechar') }}
            </b-button>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BContainer, BOverlay, VBTooltip, BRow, BCol, BButton,
} from 'bootstrap-vue'
import '@/libs/sweet-alerts'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { configSettingsScroll, formatPrice } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BSidebar,
    BContainer,
    BOverlay,
    BRow,
    BCol,
    BButton,
    VuePerfectScrollbar,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    dataPrices: {
      type: Array,
      required: true,
    },
  },
  async created() {
    this.processingAjax = false
  },
  methods: {
    closeModal(val) {
      this.$parent.showFormPrices = val
    },
  },
  setup() {
    return {
      configSettingsScroll,
      formatPrice,
    }
  },
}
</script>

<style lang="scss" scoped>

.force-padding-left-05{
  padding-left: 0.5rem !important;
}

.force-padding-0{
  padding:0 !important;
}

</style>
